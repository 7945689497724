.App {
  text-align: center;
  padding: 20px;
}

h1 {
  color: #333;
}
.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.product-card {
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 15px;
  margin: 10px;
  width: 200px;
  text-align: left;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.product-card img {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.product-card h2 {
  font-size: 18px;
  color: #333;
}

.product-card p {
  font-size: 14px;
  color: #666;
}

/* styles.css */

form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  /* background-color: #f9f9f9; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.CardElement {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
  background-color: white;
}

button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #6772e5;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:disabled {
  background-color: #bbb;
  cursor: not-allowed;
}

button:hover:not(:disabled) {
  background-color: #5469d4;
}

.Checkout{
  width:"40%"
}

._failed{ border-bottom: solid 4px red !important; }
._failed i{  color:red !important;  }

._success {
    box-shadow: 0 15px 25px #00000019;
    padding: 45px;
    width: 100%;
    text-align: center;
    margin: 40px auto;
    border-bottom: solid 4px #28a745;
}

._success i {
    font-size: 55px;
    color: #28a745;
}
._success h2 {
    margin-bottom: 12px;
    font-size: 40px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 10px;
}
._success p {
    margin-bottom: 0px;
    font-size: 18px;
    color: #495057;
    font-weight: 500;
}